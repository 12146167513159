<template>
  <div class="hour-chart-container">
    <div class="hour-chart-left">
      <div class="title">数据趋势</div>
      <a-spin class="spin" :spinning="isLoading"><div class="chart" ref="chart"></div></a-spin>
      <!-- <div class="contral">
        <a-select v-model="currentChecked" style="width:152px" @change="changeEcharts">
          <a-select-option v-for="item in checkList" :key="item.value" :value="item.value">{{ item.label }}</a-select-option>
        </a-select>
      </div> -->
    </div>
    <div class="hour-chart-right">
      <div class="right-head">选择指标</div>
      <div class="tag-list">
        <div
          class="tag-item"
          :class="{active: currentChecked === item.value}"
          @click="() => {
            if (currentChecked === item.value) return
            currentChecked = item.value
            changeEcharts()}"
          v-for="item in checkList"
          :key="item.value">{{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHourChart } from '@/apiForManage/reportdatas'
import { numFormat } from '@/utils/dealNumber'
import options from './options'
import * as echarts from 'echarts'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  mixins: [rateAndMoneyMethod],
  data () {
    return {
      currentChecked: 'impress',
      options,
      checkList: [
        {
          label: '展示',
          value: 'impress'
        },
        {
          label: '媒体消耗',
          value: 'income'
        },
        {
          label: '媒体成交eCPM',
          value: 'estimatedRevenueEcpm'
        },
        {
          label: '流量请求',
          value: 'request'
        },
        {
          label: '流量填充率',
          value: 'requestFilledRate'
        },
        {
          label: '点击',
          value: 'click'
        },
        {
          label: '点击率',
          value: 'clickRate'
        },
        {
          label: '广告请求',
          value: 'advRequest'
        },
        {
          label: '广告填充率',
          value: 'advRequestFilledRate'
        },
        {
          label: '展示率',
          value: 'impressRate'
        },
        {
          label: '展请率',
          value: 'impressRequestRate'
        },
        {
          label: '竞价填充率',
          value: 'askPriceFilledRate'
        },
        {
          label: '竞价响应eCPM',
          value: 'askPrice'
        },
        {
          label: '广告收入',
          value: 'advPrice'
        },
        {
          label: '利润率',
          value: 'profitRate'
        },
        {
          label: '流量填充',
          value: 'response'
        },
        {
          label: '竞价成功率',
          value: 'bidSuccessRate'
        },
        {
          label: '展示占比',
          value: 'display'
        },
        {
          label: '媒体消耗占比',
          value: 'revenuePercentage'
        },
        {
          label: '广告填充',
          value: 'advResponse'
        },
        {
          label: 'CPC',
          value: 'cpc'
        },
        {
          label: '广告成交CPC',
          value: 'advCpc'
        },
        {
          label: '广告收入eCPM',
          value: 'advEcpm'
        }
      ],
      isLoading: false,
      firstEcjartsList: [],
      secondEcjartsList: [],
      dateArr: [],
      dataList: [],
      dateList: [],
      colorList: [
        {
          c1: 'rgba(183, 105, 247, 1)',
          c2: 'rgba(138, 89, 251, 1)'
        },
        {
          c1: 'rgba(95, 233, 196, 1)',
          c2: 'rgba(62, 219, 223, 1)'
        },
        {
          c1: 'rgba(249, 169, 110, 1)',
          c2: 'rgba(248, 123, 104, 1)'
        },
        {
          c1: 'rgba(250, 84, 124, 1)',
          c2: 'rgba(247, 65, 108, 1)'
        },
        {
          c1: 'rgba(22, 178, 246, 1)',
          c2: 'rgba(31, 125, 238, 1)'
        },
        {
          c1: 'rgba(248, 202, 116, 1)',
          c2: 'rgba(252, 159, 74, 1)'
        }
      ]
    }
  },
  props: {
    query: {
      default: () => ({}),
      type: Object
    }
  },
  watch: {
    query: {
      handler (val) {
        this.getHoursEcharts()
      },
      deep: true
    }
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    getHoursEcharts () {
      this.isLoading = true
      getHourChart(this.query).then((resp) => {
        // x轴 24小时
        const arr = []
        for (let i = 0; i < 24; i++) {
          arr.push(i)
        }
        this.options.xAxis.data = arr
        this.dateArr = this.query.dateList
        if (resp.code === 200) {
          // Js对象obj按照键名key排序
          const newObj = {}
          Object.keys(resp.data)
            .sort()
            .map((key) => {
              newObj[key] = resp.data[key]
            })
          this.dataList = Object.values(newObj)
          this.dateList = Object.keys(newObj)
        }
        this.isLoading = false
        this.changeEcharts()
      })
    },
    changeEcharts () {
      this.options.series = []
      this.options.legend.data = []
      let index
      this.dataList.length > 0 &&
        this.dataList[0].forEach((child, c) => {
          if (child.field === this.currentChecked) {
            index = c
          }
        })
      this.dateArr.forEach((item, i) => {
        // 若未返回数据则不做以下处理
        if (!this.dataList.length || this.dataList.length <= i) return
        this.options.legend.data.push(this.dateList[i] + '-' + this.dataList[i][index].name)
        this.options.series.push({
          name: this.dateList[i] + '-' + this.dataList[i][index].name,
          type: 'line',
          smooth: true,
          data: this.dataList[i][index].data.map((data) => data || 0),
          symbol: 'circle', // 设定为实心点
          symbolSize: 4, // 设定实心点的大小
          itemStyle: {
            normal: {
              color: this.colorList[i].c1,
              lineStyle: {
                color: this.colorList[i].c1,
                width: 1.8
              }
            }
          },
          areaStyle: {
            color: this.query.dateList.length <= 2 ? {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.colorList[i].c2 // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            } : 'rgba(255, 255, 255, 0)'
          }
        })
      })
      // formatter方法
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (this.isMoney(this.currentChecked)) {
            temp.value = this.moneyText + numFormat(item.value, 3, '')
          } else if (this.isRota(this.currentChecked)) {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
        })

        return params[0].axisValue + '时<br>' + str
      }
      this.echartsInit()
    },
    echartsInit () {
      this.myEcharts && this.myEcharts.dispose()
      if (!this.$refs.chart) return
      this.myEcharts = echarts.init(this.$refs.chart, null)
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.hour-chart-container {
  border-radius: @mediumRadius;
  height: 480px;
  position: relative;
  display: flex;
  background: #fff;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  .hour-chart-left{
    padding: @smallMargin;
    padding-right: 0;
    display: flex;
    flex-direction: column;
    width: calc(100% - 152px);
    height: 100%;
    .title{
      font-size: 16px;
      font-weight: 500;
      padding-left: 16px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        width: 6px;
        height: 12px;
        border-radius: 99px;
        background-color: @primary-color;
        transform: translateY(50%);
      }
    }
    .spin {
      display: inline-block;
      flex: 1 auto;
      width: 100%;
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .chart {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .hour-chart-right{
    flex: 1 auto;
    user-select: none;
    border-left: 1px solid @compBorderColor;
    text-align: center;
    display: flex;
    flex-direction: column;
    .right-head{
      padding:  10px 0;
      color: @compValueColor;
      font-weight: 500;
      line-height: 20px
    }
    .tag-list{
      flex: 1 auto;
      overflow-y: auto;
      .tag-item{
        cursor: pointer;
        padding: 8px 0;
        color: #5a607f;
        font-weight: 400;
        &.active{
          background-color: @primary-color;
          color: #fff;
        }
      }
    }
  }
}
</style>
