/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-14 16:47:31
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-20 14:10:07
 * @FilePath: /mediatom-web/src/components/MediaChart/targets.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [{
    name: '流量请求',
    id: 'request'
  },
  {
    name: '流量填充',
    id: 'response'
  },
  {
    name: '流量填充率',
    id: 'requestFilledRate'
  },
  {
    name: '展示',
    id: 'impress'
  },
  {
    name: '点击',
    id: 'click'
  },
  {
    name: '点击率',
    id: 'clickRate'
  },
  {
    name: 'eCPM',
    id: 'ecpm'
  },
  {
    name: '预估收益',
    id: 'income'
  }
]
