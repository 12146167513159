<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 10:42:54
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-11 15:33:46
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Warning/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="warning-report-container">
    <div class="search-box">
      <div class="left">
        <a-range-picker
          class="search-item"
          format="YYYY-MM-DD"
          style="width: 240px"
          :placeholder="['Start', 'End']"
          @change="ChangeDate"
          :value="rangePickerDate"
          :allowClear="false"
          :ranges="{
            今日: [initDate(0, 0).start, initDate(0, 0).end],
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :disabledDate="disabledDate"
        >
        </a-range-picker>
        <m-select
          v-model="searchquery.target"
          :showSearch="false"
          :dataList="targetList"
          label="预警对象"
          class="search-item"
          @change="handleAutoSearch"
        />
        <m-select
          v-model="searchquery.createUser"
          :showSearch="false"
          :dataList="adminUserList"
          label="账户"
          class="search-item"
          @change="handleAutoSearch"
          v-if="role === 'SUPER_ADMIN'"
        />
        <m-select
          v-model="searchquery.sts"
          :showSearch="false"
          :dataList="[
            { name: '开启', id: 'A' },
            { name: '关闭', id: 'S' }
          ]"
          label="状态"
          class="search-item"
          @change="handleAutoSearch"
        />
        <a-input
          @change="handleInputName"
          placeholder="请输入预警名称"
          v-model.trim="searchquery.name"
          class="search-item input"
        ></a-input>
      </div>
      <div class="right">
        <a-button type="primary" @click="$router.push({ name: 'warning' })">预警管理</a-button>
      </div>
    </div>
    <div class="table-container">
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <!-- 数据时间 -->
          <el-table-column prop="date" label="数据时间" min-width="140">
          </el-table-column>
          <!-- 创建账户 -->
          <el-table-column prop="company" label="创建账户" v-if="role === 'SUPER_ADMIN'" min-width="180">
          </el-table-column>
          <!-- 预警名称 -->
          <el-table-column prop="name" label="预警名称" min-width="180">
          </el-table-column>
          <!-- 预警状态 -->
          <el-table-column prop="sts" label="预警状态" min-width="100">
            <template slot-scope="{ row }">
              {{ row.sts === 'S' ? '关闭' : row.sts === 'A' ? '开启' : '--' }}
            </template>
          </el-table-column>
          <!-- 预警对象 -->
          <el-table-column prop="target" label="预警对象" min-width="120">
            <template slot-scope="{ row }">
              {{ getTargetName(row.target) }}
            </template>
          </el-table-column>
          <!-- 预警频率 -->
          <el-table-column prop="frequency" label="预警频率" min-width="120">
            <template slot-scope="{ row }">
              {{ +row.frequency === 1 ? '天级' : +row.frequency === 2 ? '小时级' : '--' }}
            </template>
          </el-table-column>
          <!-- 预警通知时间 -->
          <el-table-column prop="noticeDate" label="预警通知时间" min-width="150">
            <template slot-scope="{ row }">
              {{ row.noticeDate || '--' }}
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column label="操作" min-width="200">
            <div slot-scope="{ row }" class="action">
              <span class="btn primary-text" @click="handleDetail(row)">查看详情</span>
            </div>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
    <WarningDetalModel
      :visible="warningDetailVisible"
      @modalCancel="warningDetailVisible = false"
      :warningInfo="warningInfo"
    />
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDuration } from '@/utils/duration'
import { mapState } from 'vuex'
import { getWarningReportPage } from '@/apiForManage/report/warning'
import { targetList } from '@/viewsForManage/FunctionList/EditWarning/BaseForm/targetList'
import WarningDetalModel from './WarningDetalModel'
export default {
  name: 'WarningReport',
  components: { WarningDetalModel },
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        endDate: '',
        startDate: '',
        target: undefined,
        createUser: undefined,
        sts: undefined,
        name: undefined,
        page: 1,
        limit: 10
      },
      isLoading: false,
      tableData: [],
      total: 0,
      timer: undefined,
      targetList,
      warningDetailVisible: false,
      warningInfo: {}
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    })
  },
  created () {
    this.initPageDate()
    this.getAdminUserList()
    this.getDataList()
  },
  methods: {
    getTargetName (target) {
      return this.targetList.find((item) => item.id === target)?.name
    },
    handleDetail (row) {
      this.warningInfo = JSON.parse(JSON.stringify(row))
      this.warningDetailVisible = true
    },
        handleInputName () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
      this.timer = setTimeout(() => {
        this.handleAutoSearch()
      }, 1000)
    },

    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    initPageDate () {
      // 初始化时间
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.searchquery.endDate = endDate
      this.searchquery.startDate = startDate
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        endDate: dateString[1],
        startDate: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      const { data = {} } = await getWarningReportPage(this.searchquery)
      const { total = 0, items = [] } = data
      this.tableData = items
      this.total = total
      this.isLoading = false
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }

  }
}
</script>

<style lang="less" scoped>
.warning-report-container {
  padding: 0 @mediumMargin @mediumMargin @mediumMargin;
  .search-box {
    display: flex;
    justify-content: space-between;
    .left {
      display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        box-sizing: border-box;
        .search-item {
          box-sizing: border-box;
          width: 200px;
          margin-right: @compMargin;
        }
      }
      .right{
        button{
          width: 134px;
        }
      }
    }
      .table-container {
    margin-top: @mediumMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: 0 @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .action {
      display: flex;
      .btn {
        cursor: pointer;
        &:first-child {
          margin-right: @compMargin;
        }
      }
    }
    .pagination {
      padding: @smallMargin 0;
    }
  }

}
</style>
