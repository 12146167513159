/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-12 18:33:35
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-19 11:11:11
 * @FilePath: /mediatom-web/src/apiForManage/mediaDashboard.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const dashboardApi = {
  // 媒体首页数据趋势/媒体报表数据趋势
  getMediaTrend: '/dashboard/mediaTrend',
  getMediaBoardData: '/dashboard/mediaSummaryData'
}
export function getMediaTrend (data) {
  return request({
    url: dashboardApi.getMediaTrend,
    method: 'post',
    data
  })
}

export function getMediaBoardData () {
  return request({
    url: dashboardApi.getMediaBoardData,
    method: 'post'
  })
}
