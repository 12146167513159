<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-30 14:19:19
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/MediaReport/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-report-container">
    <MediaSearch @handleSearch="handleSearch"/>
    <MediaChart ref="chart"/>
    <MediaTable ref="table"/>
  </div>
</template>

<script>
import MediaSearch from './MediaSearch'
import MediaTable from './MediaTable'
import MediaChart from './MediaChart'
export default {
  components: { MediaSearch, MediaTable, MediaChart },
  methods: {
    handleSearch (query) {
      this.$nextTick(() => {
        this.$refs.table.handleSearch(query)
        this.$refs.chart.handleSearch(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.media-report-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
}
</style>
