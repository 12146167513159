import store from '@/store'
const title = store.getters.title
export default {
  targetArr: [{
      title: `流量数据`,
      list: [{
          label: '流量请求',
          width: 140,
          prop: 'request',
          align: 'left',
          desc: `供应商向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
          sortable: true
        },
        {
          label: '流量填充',
          width: 140,
          prop: 'response',
          align: 'left',
          desc: `汇总行表示流量填充，即供应商向${title}发送请求后有广告返回的次数；其余行表示广告填充，即${title}向广告平台发送请求后返回成功次数`,
          sortable: true
        },
        {
          label: '流量填充率',
          width: 120,
          prop: 'requestFilledRate',
          align: 'left',
          desc: `供应商向${title}发送请求后，响应成功的占比`,
          sortable: true
        }
      ]
    },
    {
      title: '预算数据',
      list: [{
          label: '广告请求',
          width: 120,
          prop: 'advRequest',
          align: 'left',
          desc: `汇总行表示流量请求，即供应商向${title}发起请求的次数；其他行表示广告请求，即${title}向广告平台发起请求的次数`,
          sortable: true
        },
        {
          label: '广告填充率',
          width: 120,
          prop: 'advRequestFilledRate',
          align: 'left',
          desc: `广告填充率，即${title}向广告平台发送请求后返回成功的占比`,
          sortable: true
        },
        {
          label: '广告填充',
          width: 130,
          prop: 'advResponse',
          align: 'left',
          sortable: true,
          desc: `广告填充，即${title}向广告平台发送请求后返回广告填充的个数`
        }
      ]
    },
    {
      title: '展示和点击数据',
      list: [
        {
          label: '展示',
          width: 150,
          prop: 'impress',
          align: 'left',
          desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
          sortable: true
        },
        {
          label: '展示（去重）',
          width: 150,
          prop: 'uniqImpress',
          align: 'left',
          desc: `展示（去重）`,
          sortable: true
        },
        {
          label: '展示率',
          width: 120,
          prop: 'impressRate',
          align: 'left',
          desc: `${title}收到来自广告平台的广告返回后，展示成功的占比。公式为 展示率 = (展示/填充)`,
          sortable: true
        },
        {
          label: '展请率',
          width: 140,
          prop: 'impressRequestRate',
          align: 'left',
          desc: `${title}统计到的展示占${title}收到的请求比例；`,
          sortable: false
        }, {
          label: '点击',
          width: 120,
          prop: 'click',
          align: 'left',
          desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异`,
          sortable: true
        },
        {
          label: '点击率',
          width: 120,
          prop: 'clickRate',
          align: 'left',
          desc: `${title}统计的点击率，点击率=点击数/展示数`,
          sortable: false
        }
      ]
    },
    {
      title: '成本和收入数据',
      list: [{
          label: '媒体消耗',
          width: 150,
          prop: 'income',
          align: 'left',
          desc: `媒体消耗=SUM(${title} Adx统计到的媒体展示成功后上报的展示价格*${title}统计到的展示次数/1000)`,
          sortable: true
        },
        {
          label: '广告收入',
          width: 150,
          prop: 'advPrice',
          align: 'left',
          desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
          sortable: true
        },
          {
            label: '收入（分成后）',
            width: 150,
            prop: 'shareIncome',
            align: 'left',
            desc: `分成后收入`,
            sortable: true
          },
          {
            label: '利润',
            width: 120,
            prop: 'profit',
            align: 'left',
            desc: `成交额 - 媒体消耗`,
            sortable: true
          },
        {
          label: '利润率',
          width: 120,
          prop: 'profitRate',
          align: 'left',
          desc: `(成交额-媒体消耗)/成交额*100%`,
          sortable: true
        },
        {
          label: '利润（分成后）',
          width: 150,
          prop: 'shareProfit',
          align: 'left',
          desc: `分成后利润`,
          sortable: true
        },
        {
          label: '万次请求收益',
          width: 130,
          prop: 'advRequestPrice',
          align: 'left',
          sortable: true,
          desc: `（广告收入/广告请求）*10000`
        },
        {
          label: 'A成本',
          width: 150,
          prop: 'shouHuanChengBen',
          align: 'left',
          sortable: true,
          desc: `成本=媒体消耗/首唤数`
        }
      ]
    },
    {
      title: '价格数据',
      list: [{
          label: '竞价响应eCPM',
          width: 180,
          prop: 'askPrice',
          align: 'left',
          desc: `竞价广告平台在竞价响应时返回的平均eCPM`,
          sortable: true
        },
        {
          label: '媒体成交eCPM',
          width: 150,
          prop: 'estimatedRevenueEcpm',
          align: 'left',
          desc: `（媒体消耗/${title}统计的展示）*1000`,
          sortable: true
        },
        {
          label: '广告收入eCPM',
          width: 130,
          prop: 'advEcpm',
          align: 'left',
          sortable: true,
          desc: `（广告收入/${title}统计的展示）*1000`
        }, {
          label: '媒体消耗CPC',
          width: 100,
          prop: 'cpc',
          align: 'left',
          desc: `媒体消耗/点击`,
          sortable: false
        },
        {
          label: '广告成交CPC',
          width: 130,
          prop: 'advCpc',
          align: 'left',
          sortable: true,
          desc: `广告收入/点击`
        },
        {
          label: '竞价总和',
          width: 130,
          prop: 'askPriceSum',
          align: 'left',
          sortable: true,
          desc: `竞价广告平台在竞价响应时返回的出价总和`
        }
      ]
    },
    {
      title: 'CPA数据',
      list: [{
          label: '唤端',
          width: 130,
          prop: 'huanduan',
          align: 'left',
          sortable: true,
          desc: `广告主回调的唤醒成功数`
        },
        {
          label: '巨浪唤端',
          width: 130,
          prop: 'julangHuanduan',
          align: 'left',
          sortable: true,
          desc: `广告主回调的巨浪唤醒成功数`
        },
        {
          label: '巨浪⾸唤',
          width: 130,
          prop: 'julangShouhuan',
          align: 'left',
          sortable: true,
          desc: `广告主回调的巨浪首次唤醒成功数`
        },
        {
          label: '巨浪全⽹⾸唤',
          width: 160,
          prop: 'quanwangShouhuan',
          align: 'left',
          sortable: true,
          desc: `广告主回调的巨浪全网首次唤醒成功数`
        },
        {
          label: '唤起率',
          width: 130,
          prop: 'huanqiRate',
          align: 'left',
          sortable: true,
          desc: `巨浪换端/点击*100`
        },
        {
          label: '首唤率',
          width: 130,
          prop: 'shouhuanRate',
          align: 'left',
          sortable: true,
          desc: `巨浪全网首唤/点击*100`
        },
        {
          label: '唤端认可率',
          width: 160,
          prop: 'huanduanConfirmRate',
          align: 'left',
          sortable: true,
          desc: `巨浪全网首唤/巨浪首唤*100`
        },
        {
          label: 'CPA收入',
          width: 130,
          prop: 'transformPrice',
          align: 'left',
          sortable: true,
          desc: `巨浪全网首唤*单价（平台配置）`
        },
        {
          label: 'CPA利润',
          width: 130,
          prop: 'cpaProfit',
          align: 'left',
          sortable: true,
          desc: `CPA收入 - 媒体消耗`
        },
        {
          label: '首唤成本',
          width: 130,
          prop: 'advPriceQuanwangShouhuan',
          align: 'left',
          sortable: true,
          desc: `广告收入/巨浪全⽹⾸唤`
        }
      ]
    },
    {
      title: '其他数据',
      list: [{
          label: '竞价响应率',
          width: 150,
          prop: 'askPriceFilledRate',
          align: 'left',
          desc: `竞价广告平台竞价响应率 = 竞价响应次数 / 竞价次数 * 100%`,
          sortable: true
        },
        {
          label: '竞价成功率',
          width: 140,
          prop: 'bidSuccessRate',
          align: 'left',
          desc: `-`,
          sortable: false
        },
        {
          label: '展示占比',
          width: 140,
          prop: 'display',
          align: 'left',
          desc: `${title}统计的各维度下的广告展示占比；`,
          sortable: false
        },
        {
          label: '媒体消耗占比',
          width: 120,
          prop: 'revenuePercentage',
          align: 'left',
          desc: `${title}统计的各维度下的媒体消耗占比；`,
          sortable: false
        },

        {
          label: '唤醒成功率',
          width: 100,
          prop: 'awakenSuccessRate',
          align: 'left',
          desc: `点击数据中APP唤醒成功的占比`,
          sortable: false
        },

        {
          label: '超时率',
          width: 130,
          prop: 'timeoutRate',
          align: 'left',
          sortable: true,
          desc: `${title}统计到的上游广告平台在规定时间内未成功响应广告的占比`
        }
      ]
    }
  ],
  sureArr: [{
      label: '流量请求',
      width: 140,
      prop: 'request',
      align: 'left',
      desc: `供应商向${title}发送请求的次数，一次流量请求可能触发多次广告请求`,
      sortable: true
    },
    {
      label: '展示',
      width: 150,
      prop: 'impress',
      align: 'left',
      desc: `${title}统计的广告曝光次数，由于统计口径差异、网络丢包等因素，${title}统计的展示数据与广告平台展示数据可能存在差异`,
      sortable: true
    },
    {
      label: '点击',
      width: 120,
      prop: 'click',
      align: 'left',
      desc: `${title}统计的广告点击数，由于统计口径差异、网络丢包等因素，${title}统计的点击数据与广告平台点击数据可能存在差异`,
      sortable: true
    },
    {
      label: '点击率',
      width: 120,
      prop: 'clickRate',
      align: 'left',
      desc: `${title}统计的点击率，点击率=点击数/展示数`,
      sortable: false
    },
    {
      label: '媒体成交eCPM',
      width: 150,
      prop: 'estimatedRevenueEcpm',
      align: 'left',
      desc: `（媒体消耗/${title}统计的展示）*1000`,
      sortable: true
    },
    {
      label: '媒体消耗CPC',
      width: 100,
      prop: 'cpc',
      align: 'left',
      desc: `媒体消耗/点击`,
      sortable: false
    },
    {
      label: '媒体消耗',
      width: 150,
      prop: 'income',
      align: 'left',
      desc: `媒体消耗=SUM(${title} Adx统计到的媒体展示成功后上报的展示价格*${title}统计到的展示次数/1000)`,
      sortable: true
    },
    {
      label: '广告收入',
      width: 150,
      prop: 'advPrice',
      align: 'left',
      desc: `${title}平台统计的竞胜后上报给上游广告平台的成交价格，该价格仅供参考，实际结算以第三方广告平台为准。`,
      sortable: true
    }
  ]
}
