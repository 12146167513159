<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-23 14:31:42
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="synth-Report-container">
    <SynthTemplate @getTemplateInfo="getTemplateInfo" @changeQuery="changeQuery"/>
    <SynthSearth ref="search" @handleAutoSearch="handleAutoSearch" />
    <SynthChart :query="query" />
    <SynthTable ref="table" />
  </div>
</template>

<script>
import SynthTemplate from './SynthTemplate'
import SynthSearth from './SynthSearch'
import SynthTable from './SynthTable'
import mixDate from '@/mixins/initDate'
import SynthChart from './SynthChart'
export default {
  name: 'SynthReport',
  mixins: [mixDate],
  components: { SynthTemplate, SynthSearth, SynthTable, SynthChart },
  data () {
    return {
      query: {}
    }
  },
  mounted () {},
  methods: {
    // 搜索项参数处理
    handleAutoSearch (val) {
      this.query = JSON.parse(JSON.stringify(val))
      this.$nextTick(() => {
        this.$refs.table.dealQuery(this.query)
      })
    },
    getTemplateInfo (callback) {
      callback(this.$refs.table.searchquery)
    },
    changeQuery (template) {
      this.$refs.search.changeQuery(template)
    }
  }
}
</script>

<style lang="less">
.synth-Report-container {
  margin:0 @mediumMargin;
}
</style>
