<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-28 17:50:40
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-24 16:37:12
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/HoursReport/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="hours-Report-container">
    <HoursSearth @handleAutoSearch="handleAutoSearch" />
    <HourChart :query="query" />
    <HourTable ref="table"/>
  </div>
</template>

<script>
import HoursSearth from './HoursSearch'
import HourChart from './HourChart'
import HourTable from './HourTable'
export default {
  name: 'HoursReport',
  components: { HoursSearth, HourChart, HourTable },
  data () {
    return {
      query: {}
    }
  },
  methods: {
    handleAutoSearch (val) {
      this.query = JSON.parse(JSON.stringify(val))
      this.$nextTick(() => {
        this.$refs.table.dealquery(this.query)
      })
    }
  }
}
</script>

<style lang="less">
.hours-Report-container {
  margin: 0 @mediumMargin @mediumMargin @mediumMargin;
}
</style>
