<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-14 16:45:04
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-30 15:00:50
 * @FilePath: /mediatom-web/src/components/MediaChart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-chart-container">
    <Chart :option="option" />
  </div>
</template>

<script>
import Chart from '@/components/common/Chart'
import targets from './targets'
import { numFormat } from '@/utils/dealNumber'
import { getMediaTrend } from '@/apiForManage/mediaDashboard'
import { getRandomColors } from '@/data/colorList'
import options from './option'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  name: 'MediaChart',
  mixins: [rateAndMoneyMethod],
  components: { Chart },
  data () {
    return {
      targets,
      lable: [],
      list: []
    }
  },
  props: {
    target: {
      default: targets[0].id,
      type: String
    }
  },
  computed: {
    option () {
      const op = JSON.parse(JSON.stringify(options))
      op.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (item.seriesName === 'eCPM' || item.seriesName === '预估收益') {
            temp.value = this.moneyText + numFormat(item.value, 3, '')
          } else if (item.seriesName === '流量填充率' || item.seriesName === '点击率') {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str +=
            `<div style='width:100%;display:flex;justify-content:space-between'><span>` +
            item.marker +
            item.seriesName +
            ': </span>' +
            temp.value +
            '</div>'
        })
        return params[0].axisValue + '<br>' + str
      }
      const color = getRandomColors(1)[0]
      op.series[0].itemStyle.color = color
      op.series[0].lineStyle = {
        width: 3,
        color
      }
      op.series[0].areaStyle.color = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color // 0% 处的颜色
          },
          {
            offset: 1,
            color: '#fff' // 100% 处的颜色
          }
        ],
        globalCoord: false // 缺省为 false
      }
      op.xAxis.data = this.lable
      const field = this.list.filter((item) => item.field === this.target)[0] || {}
      const { data = [], name } = field
      op.series[0].data = data
      op.series[0].name = name
      return op
    }
  },
  methods: {
    async getChartData (query) {
      const { data = {} } = await getMediaTrend(query)
      const { lable = [], list = [] } = data
      this.lable = lable
      this.list = list
    }
  }
}
</script>

<style lang="less" scoped>
.media-chart-container {
  width: 100%;
  height: 100%;
}
</style>
