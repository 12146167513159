/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-14 17:01:37
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-03-15 17:25:20
 * @FilePath: /mediatom-web/src/components/MediaChart/option.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  tooltip: {
    trigger: 'axis',
    transitionDuration: 0,
    extraCssText: 'width: 180px',
    // confine: true,
    appendToBody: true,
    formatter: (params) => {
      let str = ''
      params.forEach((item, index) => {
        const temp = {}
        if (
          item.seriesName === '收益' ||
          item.seriesName === 'eCPM' ||
          item.seriesName === '预估收益' ||
          item.seriesName === 'ARPDAU'
        ) {
          temp.value = '￥' + item.value
        } else if (item.seriesName === '渗透率') {
          temp.value = item.value + '%'
        } else {
          temp.value = item.value
        }
        str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
      })
      return params[0].axisValue + '<br>' + str
    }
  },
  legend: {
    data: [],
    top: '0%'
  },
  grid: {
    top: '10%',
    left: '2%',
    right: '3%',
    bottom: '0%',
    containLabel: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: '#8d8d8d',
        width: 0.5
      }
    }
  },
  yAxis: [
    {
      type: 'value',
      position: 'left',
      axisLabel: {
        formatter: '{value}',
        show: true,
        color: '#8d8d8d'
      },
      axisLine: {
        show: true
      },
      axisTick: {
        show: true
      },
      splitLine: {
        show: true
      }
    }
  ],
  series: [
    {
      name: '',
      type: 'line',
      // stack: 'Total',
      areaStyle: {
        color: 'rgba(51, 102, 255, 0.1)'
      },
      smooth: true,
      yAxisIndex: 0,
      data: [],
      symbol: 'circle', // 设定为实心点
      symbolSize: 5, // 设定实心点的大小
      itemStyle: {
          color: 'rgba(51, 102, 255, 1)',
          lineStyle: {
            color: 'rgba(51, 102, 255, 1)',
            width: 3
          }
      }
    }
  ]
}
