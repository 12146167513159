/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-08-08 14:14:12
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-08-08 14:26:40
 * @FilePath: /mediatom-web/src/apiForManage/report/cpaHours.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getCpaHoursReport: '/report/cpaHourReport',
  getCpaHoursChart: '/report/cpaHourTrend'
}

export default api
export function getCpaHoursReport (data) {
  return request({
    url: api.getCpaHoursReport,
    method: 'post',
    data
  })
}
export function getCpaHoursChart (data) {
  return request({
    url: api.getCpaHoursChart,
    method: 'post',
    data
  })
}
