/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-19 17:19:43
 * @FilePath: /apiForManage/thirdParty.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 获取上传记录
export function getThirdPartyrecord (data) {
  return request({
    url: '/report/record',
    method: 'post',
    data
  })
}
// 下载模板
export function downloadTemplate (data) {
  return request({
    url: '/report/downloadTemplate',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 提交审核
export function upload (data) {
  return request({
    url: '/report/upload',
    method: 'post',
    data
  })
}
