/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-09 16:42:32
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-11 14:05:12
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Consume/dimesion.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
    {
      name: '日期',
      prop: 'date',
      demision: 'date',
      disabled: true
    },
    {
      name: '账户',
      prop: 'userName',
      demision: 'user_id',
      disabled: true
    },
    {
      name: '费用类型',
      prop: 'consumeType',
      demision: 'type',
      disabled: false
    }
]
