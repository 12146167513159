/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-02-27 18:52:41
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-09 17:05:27
 * @FilePath: /mediatom-web/src/apiForManage/creativeMask.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
const baseUrl = '/report'
// 获取消耗报表
export function chargingReport (data) {
  return request({
    url: `${baseUrl}/adminFeeReport`,
    method: 'post',
    data
  })
}
