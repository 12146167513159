/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-30 16:26:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-23 12:27:28
 * @FilePath: /mediatom-web/src/apiForManage/report/synthReport.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getReportTemplateList: '/reportTemplate/getReportTemplateList',
  getReportTemplateById: '/reportTemplate/getReportTemplateById',
  updateReportTemplateSts: '/reportTemplate/updateReportTemplateSts',
  insertReportTemplate: '/reportTemplate/insertReportTemplate',
  getFullTrend: '/report/fullTrend'
}

export default api
/**
 * @description 查询模版列表
 * @export
 * @returns
 */
export function getReportTemplateList () {
  return request({
    url: api.getReportTemplateList,
    method: 'post'
  })
}

/**
 *
 * @description 查询模版详情
 * @export
 * @param {any} id
 * @returns
 */
export function getReportTemplateById (id) {
  return request({
    url: api.getReportTemplateById,
    method: 'post',
    data: {
      id
    }
  })
}

/**
 * @description 更新模版状态
 * @export
 * @param {any} data
 * @returns
* */
export function updateReportTemplateSts (data) {
  return request({
    url: api.updateReportTemplateSts,
    method: 'post',
    data
  })
}

/**
 * @description 新增编辑模版
 * @export
 * @param {any} data
 * @returns
 */
export function insertReportTemplate (data) {
  return request({
    url: api.insertReportTemplate,
    method: 'post',
    data
  })
}

/**
 * @description 获取adx图标数据
 * @export
 * @param {any} data
 * @returns
 */
export function getFullTrend (data) {
  return request({
    url: api.getFullTrend,
    method: 'post',
    data
  })
}
