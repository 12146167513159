<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-11-08 10:27:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-11-13 18:04:21
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/Consume/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="consume-report-container">
    <div class="search-box">
      <a-range-picker
        class="search-item"
        format="YYYY-MM-DD"
        style="width: 240px"
        :placeholder="['Start', 'End']"
        @change="ChangeDate"
        :value="rangePickerDate"
        :allowClear="false"
        :ranges="{
          今日: [initDate(0, 0).start, initDate(0, 0).end],
          昨日: [initDate(1, 1).start, initDate(1, 1).end],
          最近一周: [initDate(6, 0).start, initDate(6, 0).end],
          最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
        }"
        :disabledDate="disabledDate"
      >
      </a-range-picker>
      <m-select-more
        class="search-item"
        v-model="searchquery.userIdList"
        :allData="adminUserList"
        :searchById="true"
        :hasIcon="false"
        :showId="false"
        label="账户"
        :showSelectedPart="true"
        :hasSearch="true"
        width="450px"
        :canSelectAll="true"
        @change="handleSearch"
      />
      <m-select
        v-model="searchquery.consumeType"
        :showSearch="false"
        :dataList="types"
        label="费用类型"
        class="search-item"
        @change="handleSearch"
      />
    </div>
    <div class="table-box">
      <div class="group-list">
        <a-checkbox-group v-model="searchquery.groupByList" @change="handleSearch">
          <a-checkbox :disabled="item.disabled" v-for="item in demisions" :key="item.demision" :value="item.demision">{{ item.name }}</a-checkbox>
        </a-checkbox-group>
      </div>
      <a-spin :spinning="isLoading">
        <el-table :data="tableData" style="width: 100%">
          <template v-for="item in demisions">
            <el-table-column v-if="searchquery.groupByList.includes(item.demision)" :key="item.demision" :prop="item.prop" :label="item.name" min-width="140">
              <template slot-scope="{ row }">
                <template v-if="item.demision === 'type'">{{ types.find((item) => item.id === row.consumeType)?.name }}</template>
                <template v-else>{{ row[item.prop] || '--' }}</template>
              </template>
            </el-table-column>
          </template>
          <!-- 费用 -->
          <el-table-column prop="amount" label="费用" min-width="140">
            <template slot-scope="{ row }">
              {{ moneyText }}{{ numFormat(row.amount || 0, 3, '') }}
            </template>
          </el-table-column>
          <!--账户余额 -->
          <el-table-column prop="residueAmount" label="账户余额" min-width="140">
            <template slot-scope="{ row }">
              {{ moneyText }}{{ numFormat(row.residueAmount || 0, 3, '') }}
            </template>
          </el-table-column>
          <!--备注 -->
          <el-table-column prop="remark" label="备注" min-width="140">
            <template slot-scope="{ row }">
              {{ row.remark || '--' }}
            </template>
          </el-table-column>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 0"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          :show-total="(total) => `共 ${total} 条`"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
  </div>
</template>

<script>
import mixDate from '@/mixins/initDate'
import { getDuration } from '@/utils/duration'
import mixGetList from '@/mixins/getListForManage'
import { chargingReport } from '@/apiForManage/report/consume'
import { numFormat } from '@/utils/dealNumber'
import demisions from './dimesion'
import mixRateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  name: 'ConsumeReport',
  mixins: [mixDate, mixGetList, mixRateAndMoneyMethod],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        userIdList: [],
        consumeType: undefined,
        groupByList: ['date', 'user_id'],
        page: 1,
        limit: 10
      },
      isLoading: false,
      tableData: [],
      total: 0,
      demisions,
      types: [
        {
          name: 'QPS',
          id: 1
        },
        {
          name: '开放流量',
          id: 2
        }
      ]
    }
  },
  created () {
    this.initPageDate()
    this.getAdminUserList()
    this.getDataList()
  },
  methods: {
    numFormat,
    initPageDate () {
      // 初始化时间
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    handleSearch () {
      this.searchquery.page = 1
      this.getDataList()
    },
    async getDataList () {
      this.isLoading = true
      try {
        const { data = {} } = await chargingReport(this.searchquery)
        const { items = [], total = 0 } = data
        this.tableData = items
        this.total = total
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    handleChangePage (page) {
      this.searchquery.page = page
      this.getDataList()
    },
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getDataList()
    }
  }
}
</script>

<style lang="less" scoped>
.consume-report-container {
  padding: 0 @mediumMargin @mediumMargin @mediumMargin;
  .search-box{
    display: flex;
    .search-item {
      box-sizing: border-box;
      padding-bottom: @compMargin;
      width: 240px;
      display: inline-block;
      margin-right: @compMargin;
    }
  }
  .table-box{
    margin-top: @smallMargin;
    border-radius: @mediumRadius;
    background-color: #fff;
    padding: @smallMargin;
    border: 1px solid @modelBorderColor;
    box-shadow: @modelShadow;
    .pagination {
      padding: @smallMargin 0;
    }
  }
}
</style>
