<template>
  <div class="hours-search-container">
    <a-range-picker
      class="search-item"
      format="YYYY-MM-DD"
      style="width: 240px"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
    </a-range-picker>
    <m-select-more
      class="search-item"
      v-model="searchquery.publisherIdList"
      :allData="supplierList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="供应商"
      @change="changeSupplier"
    />
    <m-select-more
      class="search-item"
      v-model="searchquery.placeIdList"
      :allData="placeList"
      :searchById="true"
      :hasIcon="true"
      :showId="true"
      width="450px"
      label="广告位"
      @change="changePlaceIds"
    />
    <m-select-tree
      class="search-item"
      :options="areaTreeList"
      v-model="searchquery.countryCodeList"
      label="地区"
      @change="handleSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getListForManage'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        publisherIdList: [],
        placeIdList: [],
        countryCodeList: [],
        dateStart: undefined,
        dateEnd: undefined
      }
    }
  },
  computed: {
    ...mapState({
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title
    })
  },
  async created () {
    this.initPageDate()
    await this.getSupplierList(this.searchquery.userIdList)
    this.getPlaceList()
    this.getAreaTreeList()
    // 进入时搜索
    this.$emit('handleSearch', this.searchquery)
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleSearch()
    },
    initPageDate () {
      // 初始化时间
      const { endDate, startDate, end, start } = this.initDate(6, 0)
      this.rangePickerDate = [start, end]
      this.searchquery.dateEnd = endDate
      this.searchquery.dateStart = startDate
    },
    // 供应商改变查广告位
    async changeSupplier (ids) {
      this.getPlaceList(ids)
      this.changePlaceIds(this.searchquery.placeIdList)
    },
    // 广告位改变查流量分组
    async changePlaceIds (ids) {
      this.handleSearch()
    },
    handleSearch () {
      this.$emit('handleSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.hours-search-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: calc(@mediumMargin - 7px);
  .search-item {
    margin-right: @compMargin;
    margin-bottom: @compMargin;
    width: 220px;
    display: inline-block;
  }
}
</style>
