/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-30 16:26:48
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-09 15:17:31
 * @FilePath: /mediatom-web/src/apiForManage/report/synthReport.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getCostChart: '/report/feeTrend',
  getCostTable: '/report/feeReport'
}

export default api
export function getCostChart (data) {
  return request({
    url: api.getCostChart,
    method: 'post',
    data
  })
}

export function getCostTable (data) {
  return request({
    url: api.getCostTable,
    method: 'post',
    data
  })
}
