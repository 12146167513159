/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-03-14 18:58:17
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-24 14:41:14
 * @FilePath: /mediatom-web/src/data/colorList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { getRandom } from '@/utils/dealNumber'
// 颜色池
export const colorList = [
  '#61A4E4',
  '#16DBCC',
  '#FFBB38',
  '#FF82AC',
  '#7161C5',
  '#6461E4',
  '#AA61E4',
  '#E47161'
]

/**
 * 获取固定数量的随机颜色数组
 * @export
 * @param {any} num
 * @returns
* */
export function getRandomColors (num) {
  // 根据颜色池数量和参数大小返回空数组
  if (num < 1 || num > colorList.length) return []
  const colors = [...colorList]
  const arr = []
  for (let i = 0; i < num; i++) {
    // 随机索引
    const index = getRandom(0, colors.length - 1)
    arr.push(colors.splice(index, 1)[0])
  }
  return arr
}
