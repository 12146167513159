<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-26 17:00:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-09 15:11:25
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/CostManager/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="cost-manager-container">
    <CostSum ref="sum"/>
    <CostSearch @handleSearch="handleSearch"/>
    <CostChart ref="chart"/>
    <CostTable ref="table"/>
  </div>
</template>

<script>
import CostSum from './CostSum'
import CostSearch from './CostSearch'
import CostChart from './CostChart'
import CostTable from './CostTable'
export default {
  name: 'CostManager',
  components: { CostSum, CostSearch, CostChart, CostTable },
  methods: {
    handleSearch (query) {
      this.$nextTick(() => {
        this.$refs.sum.handleSearch()
        this.$refs.chart.handleSearch(query)
        this.$refs.table.handleSearch(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.cost-manager-container{
  padding: 0 @mediumMargin;
}
</style>
