/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-07-08 12:13:20
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-07-08 12:13:44
 * @FilePath: /mediatom-web/src/apiForManage/report/warning.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'

const api = {
  getWarningReportPage: '/earlyWarning/getWarningReportPage'
}

export default api
export function getWarningReportPage (data) {
  return request({
    url: api.getWarningReportPage,
    method: 'post',
    data
  })
}
