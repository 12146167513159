<!--
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-11 11:57:10
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-23 17:03:38
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/MediaReport/MediaTable/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="media-report-table">
    <div class="head">
      <div class="dimension_list">
        <a-button type="link" @click="handleSetDimesion"><a-icon type="radar-chart"/> 维度</a-button>
        <template v-for="(item, index) in baseColumns">
          <div class="dimension-item" v-if="item.prop !== dimensionEnum.date.value" :key="item.prop">
            <span class="name">{{ item.name }}</span>
            <span class="dimension-close" @click="handleCloseDimensionItem(index, item)"><a-icon type="close" /></span>
          </div>
        </template>
      </div>
      <a-button type="link" :loading="isLoading" @click="handleExportReport"><a-icon type="download" />导出 </a-button>
    </div>
    <div class="main">
      <a-spin :spinning="isLoading">
        <el-table :key="tableKey" :data="tableData" style="width: 100%" @sort-change="handleSortChange">
          <template v-for="(item) in baseColumns">
            <el-table-column
              :prop="item.prop"
              :key="item.prop"
              :label="item.name"
              :min-width="item.width"
              :fixed="false"
            >
              <template slot-scope="{ row }">
                <div :title="row[item.prop]" v-if="item.prop === dimensionEnum.appName.prop && row[item.prop]">
                  <div>{{ row[item.prop] }}</div>
                  <div>ID:{{ row.publisherId }}</div>
                </div>
                <div :title="row[item.prop]" v-else-if="item.prop === dimensionEnum.placeName.prop && row[item.prop]">
                  <div>{{ row[item.prop] }}</div>
                  <div>ID:{{ row.placeId }}</div>
                </div>
                <template v-else>
                  {{ row[item.prop] }}
                </template>
              </template>
            </el-table-column>
          </template>
          <template v-for="(item) in targetColumns">
            <el-table-column
              :prop="item.prop"
              :key="item.prop"
              :label="item.name"
              :min-width="item.width"
              :fixed="false"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                <!-- <template v-if="item.prop === 'requestFilledRate' || item.prop === 'clickRate'">{{ row[item.prop] }}%</template>
                <template v-else>{{ row[item.prop] }}</template> -->
                <template v-if="isRota(item.prop)">
                  {{ row[item.prop] }}%
                </template>
                <template v-else-if="isMoney(item.prop)">
                  {{ moneyText }}{{ numFormat(row[item.prop], 3, '') }}
                </template>
                <template v-else>{{ numFormat(row[item.prop], 3, '') }}</template>
              </template>
            </el-table-column>
          </template>
        </el-table>
        <m-empty v-if="tableData.length === 0" style="height: 341px" imgHeight="176px">
          <template #description> 暂时没有任何数据 </template>
        </m-empty>
        <a-pagination
          class="pagination"
          v-if="tableData.length > 1"
          show-size-changer
          :current="searchquery.page"
          :total="total"
          @change="handleChangePage"
          @showSizeChange="onShowSizeChange"
          :pageSize="searchquery.limit"
        />
      </a-spin>
    </div>
    <CustDimension
      v-if="dimensionVisible"
      :visible="dimensionVisible"
      :allDimesion="selectDimensionColumnsForModal"
      :groupByList="searchquery.groupByList"
      @modalCancel="dimensionVisible = false"
      @changeCustDimension="changeCustDimension"
    />
  </div>
</template>

<script>
import { targetColumns, dimensionColumns, dimensionEnum, dateColumns } from '../allColumnData'
import CustDimension from '../../SynthReport/SynthTable/CustDimension'
import { getMediaReport, exportMediaReport } from '@/apiForManage/reportdatas'
import { showDay, numFormat } from '@/utils/dealNumber'
import excel from '@/utils/excel'
import rateAndMoneyMethod from '@/mixins/rateAndMoneyMethod'
export default {
  name: 'MediaTable',
  mixins: [rateAndMoneyMethod],
  components: { CustDimension },
  data () {
    return {
      dimensionEnum,
      // 指标column
      targetColumns,
      // 可选维度数组
      selectDimensionColumns: [],
      selectDimensionColumnsForModal: [],
      // 时间column
      dateColumns,
      // 渲染table的维度columns
      baseColumns: [],
      // 是否打开维度弹框
      dimensionVisible: false,
      // 搜索参数
      searchquery: {
        page: 1,
        limit: 10,
        groupByList: [dimensionEnum.date.value],
        sortBy: undefined,
        sortOrder: undefined
      },
      tableData: [],
      total: 0,
      tableKey: '',
      isLoading: false
    }
  },
  created () {
    // 初始化维度
    this.selectDimensionColumns = dimensionColumns
    this.selectDimensionColumns.forEach((item) => {
      item.checked = false
    })
    this.baseColumns = [...this.dateColumns]
  },
  methods: {
    numFormat,
    // 导出
    async handleExportReport () {
      this.isLoading = true
      const query = JSON.parse(JSON.stringify(this.searchquery))
      query.codeList = [
        ...this.baseColumns.map((item) => item.prop),
        ...this.targetColumns.map((item) => item.prop)
      ]
      query.nameList = [
        ...this.baseColumns.map((item) => item.name),
        ...this.targetColumns.map((item) => item.name)
      ]
      try {
        const resp = await exportMediaReport(query)
        await excel(resp, `媒体报表-${showDay(new Date())}.xls`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      } finally {
        this.isLoading = false
      }
    },
    // 初始化参数
    initQuery () {
      this.searchquery.sortBy = undefined
      this.searchquery.sortOrder = undefined
      this.searchquery.page = 1
    },
    // 点击维度按钮
    handleSetDimesion () {
      this.selectDimensionColumnsForModal = JSON.parse(JSON.stringify(this.selectDimensionColumns))
      this.dimensionVisible = true
    },
    // 排序回调
    handleSortChange ({ column, prop, order }) {
      if (order === 'ascending') {
        this.searchquery.sortOrder = 'asc'
      } else if (order === 'descending') {
        this.searchquery.sortOrder = 'desc'
      } else {
        this.searchquery.sortOrder = undefined
      }
      this.searchquery.sortBy = this.searchquery.sortOrder ? prop : undefined
      this.searchquery.page = 1
      this.getTableData()
    },
    handleSearch (query) {
      this.searchquery = { ...this.searchquery, ...query }
      this.initQuery()
      this.getTableData()
      this.tableKey = '' + Math.random()
    },
    // 获取数据
    async getTableData () {
      this.isLoading = true
      try {
        const { data = {} } = await getMediaReport(this.searchquery)
        const { items = [], total = 0, aggregate = {} } = data
        aggregate.date = '汇总'
        this.tableData = items.length > 0 ? [aggregate, ...items] : []
        this.total = total
      } finally {
        this.isLoading = false
      }
    },
    // 维度删除按钮
    handleCloseDimensionItem (index, item) {
      // 去除掉column中的该项
      this.baseColumns.splice(index, 1)
      // 去除掉维度参数中的该项
      this.searchquery.groupByList = this.searchquery.groupByList.filter((i) => i !== item.key)
      // 取消弹窗选中
      this.selectDimensionColumns.forEach((child) => {
        if (child.prop === item.prop) {
          child.checked = false
        }
      })
    },
    // 维度修改回调
    changeCustDimension (arr) {
      // 获取到除日期外的维度字符串
      const groupByList = arr.map((item) => item.key)
      this.searchquery.groupByList = [dimensionEnum.date.value, ...groupByList]
      // 赋值维度column
      this.baseColumns = [...this.dateColumns, ...arr]
      // 赋值维度参数选中值
      this.selectDimensionColumns.forEach((item) => {
        if (groupByList.includes(item.key)) {
          item.checked = true
        } else {
          item.checked = false
        }
      })
      // 初始化搜索参数
      this.initQuery()
      this.getTableData()
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    }
  }
}
</script>
<style lang="less" scoped>
.media-report-table{
  background-color: #fff;
  border-radius: @mediumRadius;
  margin-top: @mediumMargin;
  padding: @smallMargin;
  border: 1px solid @modelBorderColor;
  box-shadow: @modelShadow;
  .head{
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    .dimension_list{
      display: flex;
      justify-content: flex-start;
      .dimension-item {
        padding: 0 5px;
        height: 36px;
        line-height: 36px;
        border-radius: 5px;
        font-size: 12px;
        margin-left: 10px;
        .dimension-close {
          margin-left: 5px;
          line-height: 12px;
          cursor: pointer;
        }
      }
    }
  }
  .main{
    padding: 0 10px;
    .pagination{
      padding-top: @smallMargin;
    }
  }
}
</style>
