/*
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-05-31 12:15:17
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-06-12 16:26:28
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/SynthReport/SynthTemplate/timeRangeList.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default [
  {
    name: '今日',
    value: '今日',
    range: [0, 0]
  },
  {
    name: '昨日',
    value: '昨日',
    range: [1, 1]
  },
  {
    name: '最近七日',
    value: '最近七日',
    range: [6, 0]
  },
  {
    name: '最近十四日',
    value: '最近十四日',
    range: [13, 0]
  },
  {
    name: '最近一个月',
    value: '最近一个月',
    range: [30, 0]
  }
]
