<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-10-09 11:19:55
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-10-11 14:42:10
 * @FilePath: /mediatom-web/src/viewsForManage/reportdatas/CostManager/CostChart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <a-spin :spinning="isLoading">
    <div class="cost-chart-container">
      <div class="title">
        <h4>趋势图</h4>
      </div>
      <div class="chart-wrapper">
        <Chart :option="option" />
      </div>
    </div>
  </a-spin>
</template>

<script>
import Chart from '@/components/common/Chart'
import option from './options'
import { getCostChart } from '@/apiForManage/report/cost'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
export default {
  name: 'CostChart',
  components: { Chart },
  data () {
    return {
      option,
      query: {},
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      moneyType: (state) => (+state.user.cur === 1 ? '$' : '¥'),
      currentLoginId: (state) => state.user.id
    })
  },
  methods: {
    numFormat,
    handleSearch (query) {
      this.query = query
      this.getChartData()
    },
    async getChartData () {
      this.isLoading = true
      const { data = [] } = await getCostChart(this.query)
      const opt = JSON.parse(JSON.stringify(this.option))
      opt.series = []
      const incomeSeries = {
        name: '开放流量费用',
        type: 'line',
        smooth: true,
        data: [],
        symbol: 'circle',
        symbolSize: 4,
        itemStyle: {
          normal: {
            color: 'rgba(183, 105, 247, 1)',
            lineStyle: {
              color: 'rgba(183, 105, 247, 1)',
              width: 1.8
            }
          }
        },
        areaStyle: {
          color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(138, 89, 251, 1)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            } // 颜色渐变
        }
      }
      const usageFeeSeries = {
        name: 'QPS费用',
        type: 'line',
        smooth: true,
        data: [],
        symbol: 'circle',
        symbolSize: 4,
        itemStyle: {
          normal: {
            color: 'rgba(95, 233, 196, 1)',
            lineStyle: {
              color: 'rgba(95, 233, 196, 1)',
              width: 1.8
            }
          }
        },
        areaStyle: {
          color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: 'rgba(62, 219, 223, 1)' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            } // 颜色渐变
        }
      }
      data.forEach((item) => {
        incomeSeries.data.push(item.income)
        usageFeeSeries.data.push(item.usageFee)
      })
      opt.series.push(incomeSeries, usageFeeSeries)
      opt.xAxis.data = data.map((item) => item.date)
      opt.legend.data = ['开放流量费用', 'QPS费用']
      opt.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          str += item.marker + item.seriesName + ': ' + this.moneyType + numFormat(item.value, 3, '') + '<br>'
        })

        return params[0].axisValue + '<br>' + str
      }
      this.option = opt
      this.isLoading = false
    }
  }
}
</script>

<style lang="less" scoped>
.cost-chart-container{
  border-radius: @mediumRadius;
  box-shadow: @modelShadow;
  border: 1px solid @modelBorderColor;
  background-color: #fff;
  padding: @smallMargin;
  margin-bottom: @mediumMargin;
  display: flex;
  flex-direction: column;
  .title{
    position: relative;
    padding-left: 15px;
    &::after{
      content: '';
      display: inline-block;
      height: 12px;
      width: 6px;
      background-color: @primary-color;
      position: absolute;
      top: 5px;
      left: 0;
    }
  }
  .chart-wrapper{
    min-height: 250px;
    height: 25vw;
  }
}
</style>
